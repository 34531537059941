import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import axios from '../api/axios'

const InvitationDetailsPage = () => {
  const { id } = useParams()
  const [driver, setDriver] = useState<any>()

  useEffect(() => {
    const fetchUser = async () => {
      const res = await axios.get(`/invitations/${id}`)
      setDriver(res.data)
    }

    if (id) fetchUser()
  }, [id])

  return (
    <section className="h-full">
      <div className="p-4">
        <h1 className="text-2xl text-center">드라이버 디테일</h1>
        <div className="flex justify-end gap-2">
          <Link to={`/drivers/invitations/update/${driver?.id}`}>수정</Link>
          <Link to={`/drivers`}>목록</Link>
        </div>
        <div className="p-4">
          {driver?.id && <pre>{JSON.stringify(driver, null, 2)}</pre>}
        </div>
      </div>
    </section>
  )
}

export default InvitationDetailsPage
