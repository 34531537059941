import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../api/axios'
import usePhoneNumberFormat from '../hooks/usePhonuNumberFormat'

const InvitationRegisterPage = () => {
  const [name, setName] = useState('')
  const [licenseNumber, setLicenseNumber] = useState('')
  const [address, setAddress] = useState('')
  const { phoneNumber, setPhoneNumber, handlePhoneNumberChanged } = usePhoneNumberFormat()
  const [isAgencyMaster, setIsAgencyMaster] = useState(false)
  const [email, setEmail] = useState('')
  const [checkEmail, setCheckEmail] = useState(true)
  const navigate = useNavigate()

  const params = useParams()
  const isUpdate = !!params.id
  const title = isUpdate ? '드라이버 수정' : '드라이버 등록'
  const buttonTitle = isUpdate ? '수정' : '등록'
  const apiRoute = isUpdate ? `/invitations/${params.id}` : '/invitations'
  const axiosCall = isUpdate ? axios.put : axios.post

  useEffect(() => {
    const fetchDriver = async () => {
      const res = await axios.get(`/invitations/${params.id}`)
      const { name, licenseNumber, address, phoneNumber, email, isAgencyMaster } = res.data
      setName(name)
      setLicenseNumber(licenseNumber)
      setAddress(address)
      setPhoneNumber(phoneNumber)
      setEmail(email)
      setIsAgencyMaster(isAgencyMaster)
    }

    if (isUpdate) fetchDriver()
  }, [isUpdate, params, setPhoneNumber])

  const register = useCallback(
    async (e: any) => {
      e.preventDefault()

      const emailRule = '[a-zA-Z0-9.-]\\.[a-zA-Z]{2,6}$'

      if (email.match(emailRule) === null) {
        setCheckEmail(false)
        return
      }

      const res = await axiosCall(apiRoute, {
        name,
        licenseNumber,
        address,
        phoneNumber,
        email,
        isAgencyMaster,
      })
      if (!res.data?.success) return

      navigate('/drivers')
    },
    [
      address,
      apiRoute,
      axiosCall,
      email,
      licenseNumber,
      name,
      navigate,
      phoneNumber,
      isAgencyMaster,
    ],
  )

  const deleteDriver = async () => {
    const isDelete = window.confirm(`${name}님을 삭제하시겠습니까?`)
    if (!isDelete) return
    try {
      const res = await axios.delete(`/invitations/${params.id}`)
      if (res.data.success) {
        navigate(-1)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <section className=" py-8 flex-1 flex justify-center items-center bg-background">
      <div className="bg-white w-2/5 pt-8 h-full rounded-lg">
        <h1 className="mb-8 text-2xl text-center font-bold">{title}</h1>

        <form className="px-10 w-full" onSubmit={register}>
          <div className="flex flex-col mt-5">
            <label htmlFor="name" className="mb-2">
              이름
            </label>
            <input
              value={name}
              onChange={e => setName(e.target.value)}
              id="name"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          <div className="flex flex-col mt-5">
            <label htmlFor="licenseNumber" className="mb-2">
              운전면허번호
            </label>
            <input
              value={licenseNumber}
              onChange={e => setLicenseNumber(e.target.value)}
              id="licenseNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          {/* <div className="flex flex-col mt-5">
            <label htmlFor="address" className="mb-2">
              주소
            </label>
            <input
              value={address}
              onChange={e => setAddress(e.target.value)}
              id="address"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div> */}

          <div className="flex flex-col mt-5">
            <label htmlFor="phoneNumber" className="mb-2">
              전화번호
            </label>
            <input
              value={phoneNumber}
              onChange={handlePhoneNumberChanged}
              id="phoneNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          <div className="flex flex-col mt-5">
            <label htmlFor="email" className="mb-2">
              이메일
            </label>
            <input
              value={email}
              onChange={e => {
                setCheckEmail(true)
                setEmail(e.target.value)
              }}
              id="email"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!checkEmail && <p className="text-sm text-red">이메일 형식이 올바르지 않습니다.</p>}

          <div className="inline-flex items-center">
            <label
              className="relative flex items-center p-3 rounded-full cursor-pointer"
              htmlFor="pink">
              <input
                type="checkbox"
                className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-pink-500 checked:bg-pink-500 checked:before:bg-pink-500 hover:before:opacity-10"
                id="pink"
                checked={isAgencyMaster}
                onChange={e => {
                  setIsAgencyMaster(e.currentTarget.checked)
                }}
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3.5 w-3.5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="1">
                  <path
                    fill-rule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clip-rule="evenodd"></path>
                </svg>
              </span>
            </label>
            업체대표의 드라이버 계정이라면 체크해주세요.
          </div>

          <div className="flex mt-5 justify-end gap-3 ">
            {isUpdate && (
              <button
                type="button"
                className="px-6 py-1 text-white bg-black bg-red-400 rounded"
                onClick={() => deleteDriver()}>
                삭제
              </button>
            )}

            <button
              type="button"
              className="px-6 py-1 text-white bg-red bg-red-400 rounded"
              onClick={() => navigate(-1)}>
              취소
            </button>

            <button className="px-6 py-1 text-white bg-blue-400 rounded">{buttonTitle}</button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default InvitationRegisterPage
