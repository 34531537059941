import { useState } from 'react'
import { CustomOverlayMap, Map, MapMarker, MarkerClusterer } from 'react-kakao-maps-sdk'
import { Driver } from '../pages/SalesPage'
import { selectAgency } from '../state/agencySlice'
import { useAppSelector } from '../state/store'
import agencyMarker from '../asset/agency_marker.png'

const KakaoMap = ({ drivers }: { drivers: Driver[] }) => {
  const agency = useAppSelector(selectAgency)
  const agencyLocation = {
    lat: Number(agency.coordinate.latitude),
    lng: Number(agency.coordinate.longitude),
  }
  const [center, setCenter] = useState(agencyLocation)

  return (
    <Map center={center} style={{ width: '100%', height: '100%' }}>
      <MapMarker
        position={agencyLocation}
        title={agency.name}
        clickable={true}
        image={{
          src: agencyMarker,
          size: {
            width: 40,
            height: 45,
          },
        }}
        onClick={() => setCenter(agencyLocation)}
      />

      <MarkerClusterer averageCenter={true} minLevel={8}>
        {drivers.map(d => (
          <CustomOverlayMap
            key={d.id}
            position={{ lat: d.coord.latitude, lng: d.coord.longitude }}
            clickable={true}
            yAnchor={0.8}>
            <div>
              <div className="font-bold">{d.name}</div>
              <div
                className={`w-10 h-10 rounded-full overflow-hidden border-4 ${
                  d.status === 'ready' ? 'border-[#26CB83]' : 'border-[#FF8571]'
                } mx-auto`}
                onClick={() =>
                  setCenter({
                    lat: Number(d.coord.latitude),
                    lng: Number(d.coord.longitude),
                  })
                }>
                <img className="object-cover" src={d.profileImage} alt="" />
              </div>
            </div>
          </CustomOverlayMap>
        ))}
      </MarkerClusterer>
    </Map>
  )
}

export default KakaoMap
