import { selectUser } from '../state/userSlice'
import { useAppSelector } from '../state/store'
import { Link } from 'react-router-dom'
import logo from '../asset/logo.png'
import { selectAgency } from '../state/agencySlice'
const MenuBar = () => {
  const agency = useAppSelector(selectAgency)

  return (
    <div className="sticky top-0 flex items-center justify-between h-20 px-6  py-2 bg-white border-b z-[31]">
      <Link to="/" className="">
        <img className="w-40" src={logo} alt="logo" />
        {/* <h1 className="text-2xl">스마일서비스</h1> */}
      </Link>

      <p className="text-xl">{agency?.name}</p>
    </div>
  )
}

export default MenuBar
