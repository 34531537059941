import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from '../state/store'
import { selectUser } from '../state/userSlice'

const RequireAuth = () => {
  const isLoggedIn = useAppSelector(selectUser).id > 0

  if (!isLoggedIn) return <Navigate to="/login" />
  return <Outlet />
}

export default RequireAuth
