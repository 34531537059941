import { useEffect } from 'react'
import axios from '../api/axios'
import { useAppDispatch } from '../state/store'
import { resetUser } from '../state/userSlice'

const useAxios = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      response => response,

      async error => {
        const prevRequest = error?.config

        if (error?.response?.status === 403 && !prevRequest?.sent) {
          prevRequest.sent = true

          if (prevRequest.url === '/auth/refresh-token') {
            console.log('refresh token is empty or expired')
            dispatch(resetUser())

            return Promise.reject(error)
          }

          const { data: newAccessToken } = await axios.get(
            '/auth/refresh-token',
            { withCredentials: true }
          )

          axios.defaults.headers.common.authorization = `Bearer ${newAccessToken}`
          prevRequest.headers.authorization = `Bearer ${newAccessToken}`

          return axios(prevRequest)
        }
      }
    )

    return () => axios.interceptors.response.eject(responseInterceptor)
  }, [dispatch])
}

export default useAxios
