import { useCallback, useEffect, useState } from 'react'

const useLicenseNumberFormat = (number: string = '') => {
  const [licenseNumber, setLicenseNumber] = useState(number)

  const handleLicenseNumberChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9\b -]{0,12}$/
    if (regex.test(e.target.value)) {
      setLicenseNumber(e.target.value)
    }
  }, [])

  useEffect(() => {
    if (licenseNumber.length === 4) {
      setLicenseNumber(licenseNumber.replace(/(\d{3})(\d{1})/, '$1-$2'))
    }
    if (licenseNumber.length === 9) {
      setLicenseNumber(licenseNumber.replace(/(\d{3}-)(\d{2})(\d+)/, '$1$2-$3'))
    }

    if (licenseNumber.length === 10) {
      setLicenseNumber(licenseNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3'))
    }
  }, [licenseNumber])

  return {
    licenseNumber,
    setLicenseNumber,
    handleLicenseNumberChanged,
  }
}

export default useLicenseNumberFormat
