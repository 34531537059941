import { useCallback, useEffect, useState } from 'react'

const usePhoneNumberFormat = (number: string = '') => {
  const [phoneNumber, setPhoneNumber] = useState(number)

  const handlePhoneNumberChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const regex = /^[0-9\b -]{0,13}$/
      if (regex.test(e.target.value)) {
        setPhoneNumber(e.target.value)
      }
    },
    []
  )

  useEffect(() => {
    if (phoneNumber.length === 4 && phoneNumber.startsWith('01')) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{1})/, '$1-$2'))
    }
    if (phoneNumber.length === 9 && phoneNumber.startsWith('01')) {
      setPhoneNumber(phoneNumber.replace(/(\d{3}-)(\d{4})(\d+)/, '$1$2-$3'))
    }

    if (phoneNumber.length === 10) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'))
    }

    if (phoneNumber.length === 11) {
      setPhoneNumber(phoneNumber.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3'))
    }

    if (phoneNumber.length === 13) {
      setPhoneNumber(
        phoneNumber
          .replace(/-/g, '')
          .replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
      )
    }
  }, [phoneNumber])

  return { phoneNumber, setPhoneNumber, handlePhoneNumberChanged }
}

export default usePhoneNumberFormat
