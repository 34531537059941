import { Link } from 'react-router-dom'
import { selectAgency } from '../state/agencySlice'
import { useAppSelector } from '../state/store'

const AgencyPage = () => {
  const agency = useAppSelector(selectAgency)
  const registered = agency.id > 0

  return (
    <section className="h-full p-4 mx-auto">
      <div className="mx-auto">
        <h1 className="text-2xl text-center">업체정보</h1>
        {!agency.isVerified && (
          <h2 className="text-xl text-center text-red-500">
            증빙자료를 검증중입니다.
          </h2>
        )}
        <div className="flex justify-end">
          {registered && (
            <Link to="/agency/update" className="">
              수정
            </Link>
          )}

          {!registered && (
            <Link to="/agency/register" className="">
              등록
            </Link>
          )}
        </div>
        <div className="">
          {Object.keys(agency).map(key => (
            <li className="" key={key}>
              {key}: {String(agency[key])}
            </li>
          ))}
        </div>
      </div>
    </section>
  )
}

export default AgencyPage
