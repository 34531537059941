import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ko from 'date-fns/locale/ko'
import { getMonth, getYear } from 'date-fns'
import range from 'lodash/range'
import { Dispatch, SetStateAction, forwardRef, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
registerLocale('ko', ko)

type DateStartToLast = {
  startDate?: Date
  setStartDate?: Dispatch<SetStateAction<Date>>
  lastDate?: Date
  setLastDate?: Dispatch<SetStateAction<Date>>
}

export default function CustomDatePicker({
  startDate,
  setStartDate,
  lastDate,
  setLastDate,
}: DateStartToLast) {
  const datePickerRef = useRef<any>(null)
  const years = range(2022, getYear(new Date()) + 1, 1)
  const months = [
    '1월',
    '2월',
    '3월',
    '4월',
    '5월',
    '6월',
    '7월',
    '8월',
    '9월',
    '10월',
    '11월',
    '12월',
  ]

  const openDatepicker = () => datePickerRef.current?.setOpen(true)

  return (
    <div className="flex">
      <DatePicker
        ref={datePickerRef}
        renderCustomHeader={({ date, changeYear, changeMonth }) => (
          <div
            style={{
              margin: 10,
              display: 'flex',
              justifyContent: 'center',
            }}>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(Number(value))}>
              {years.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
              {months.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        )}
        selected={startDate && lastDate ? lastDate : startDate}
        onChange={date => {
          if (date && setStartDate) setStartDate(date)
          if (date && setLastDate) setLastDate(date)
        }}
        locale={ko}
        dateFormat="yyyy-MM-dd"
        preventOpenOnFocus={true}
        customInput={<CustomInput />}
        minDate={lastDate ? startDate : null}
      />
      <div className="bg-[#ececec] px-1 hover:cursor-pointer" onClick={openDatepicker}>
        <FontAwesomeIcon width={20} height={20} icon={faCalendarAlt} color="#939393" />
      </div>
    </div>
  )
}

const CustomInput = forwardRef((props, ref: any) => (
  <input
    id="custom"
    type="text"
    style={{
      border: '1px solid #cdcdcd',
      borderRight: 'none',
      width: '120px',
      padding: '0 6px',
    }}
    ref={ref}
    {...props}
  />
))
