import { useEffect, useState } from "react";
import axios from "../api/axios";
import { faCircleMinus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VehicleRegisterPage from "../components/VehicleRegister";
import { Vehicle } from "../type/vehicle";

export default function VehicleManagementPage() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [isRegisterMode, setRegisterMode] = useState(false);

  useEffect(() => {
    const fetchVehiclelInfo = async () => {
      const res = await axios.get<{ vehicleInfo: Vehicle[] }>(
        "/organization/vehicle"
      );
      setVehicles(res.data.vehicleInfo);
    };
    fetchVehiclelInfo();
  }, []);

  const toggleRegisterMode = () => {
    setRegisterMode((p) => !p);
  };

  const removeVehicle = async (id: number) => {
    try {
      const res = await axios.delete(`/organization/vehicle/${id}`);
      console.log(res.data);

      setVehicles(vehicles.filter((vehicle) => vehicle.id !== id));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-4 flex-1 bg-background">
      <h2 className="text-3xl mb-5 ">차량관리</h2>

      <ul className="flex flex-row gap-8 flex-wrap ">
        {vehicles.map((vehicle) => (
          <li
            key={vehicle.certificateNumber}
            className="flex flex-row w-64 h-[120px] bg-white rounded-lg overflow-hidden"
          >
            <div className="items-center justify-center flex bg-sideBar w-1/5">
              <button
                onClick={() => {
                  removeVehicle(vehicle.id);
                }}
              >
                <FontAwesomeIcon
                  className="text-3xl"
                  icon={faCircleMinus}
                  color="white"
                />
              </button>
            </div>
            <div className="w-4/5 flex justify-center flex-col">
              <p
                className={`text-center mb-2 text-sm ${
                  vehicle.type === "normal" ? "text-green" : "text-red"
                }`}
              >
                {vehicle.type === "normal" ? "일반구급차" : "특수구급차"}
              </p>
              <p className="text-center text-2xl font-bold">
                {vehicle.certificateNumber}
              </p>
            </div>
          </li>
        ))}
        {isRegisterMode ? (
          <VehicleRegisterPage
            setVehicles={setVehicles}
            toggleRegisterMode={toggleRegisterMode}
          />
        ) : (
          <li className="flex flex-row w-64 h-[120px] justify-center bg-white rounded-lg overflow-hidden">
            <button onClick={toggleRegisterMode}>
              <FontAwesomeIcon
                className="text-6xl"
                icon={faCirclePlus}
                color="#ececec"
              />
            </button>
          </li>
        )}
      </ul>
    </div>
  );
}
