import { useEffect, useState } from 'react'
import { getMonth, getYear } from 'date-fns'
import axios from '../api/axios'
import CustomDatePicker from '../components/CustomDatePicker'
import CustomSelector from '../components/CustomSelector'
import { useAppSelector } from '../state/store'
import { Order } from '../type/order'
import dayjs from 'dayjs'
import Pagination from '../components/Pagination'

export interface Driver {
  id: number
  role: string
  name: string
  email: string
  phoneNumber: string
  licenseNumber: string
  isp: string
  identifier: null
  profileImage: string
  coord: {
    latitude: number
    longitude: number
  }
  location: string
  status: string
  deletedAt: null
  isAgencyMaster: boolean
}

export default function SalesPage() {
  const agency = useAppSelector(s => s.agency)
  const [drivers, setDrivers] = useState<Driver[]>([])
  const year = getYear(new Date())
  const month = getMonth(new Date())
  const [driver, setDriver] = useState('')
  const [startDate, setStartDate] = useState(new Date(year, month, 1))
  const [lastDate, setLastDate] = useState(new Date(year, month + 1, 0))
  const [orders, setOrders] = useState<Order[]>([])
  const [totalFee, setTotalFee] = useState(0)
  const [totalCommission, setTotalCommission] = useState(0)
  const [totalSale, setTotalSale] = useState(0)
  const [selectPage, setSelectPage] = useState(1)
  const [total, setTotal] = useState(0)
  const listNum = 10

  // const [startDate, setStartDate] = useState(getMonth)

  useEffect(() => {
    const getDrivers = async () => {
      const res = await axios.get<{ drivers: Driver[] }>('/organization/drivers')
      setDrivers(res.data.drivers)
    }
    getDrivers()
  }, [])

  const getSales = async () => {
    try {
      const res = await axios.get<{ orders: [Order[], number] }>(
        `/organization/sales/${startDate}/${lastDate}/${agency.id}?driverId=${driver}&page=${selectPage}&listNum=${listNum}`,
      )
      setTotal(res.data.orders[1])
      const result = res.data.orders[0]
      const totalFeeResult = result.reduce((acc, curr) => (acc += curr.invoice.totalFee), 0)
      const totalCommissionResult = result.reduce((acc, curr) => (acc += curr.invoice.heroFee), 0)
      const totalSaleResult = totalFeeResult - totalCommissionResult

      setOrders(result)
      setTotalFee(totalFeeResult)
      setTotalCommission(totalCommissionResult)
      setTotalSale(totalSaleResult)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getSales()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPage])

  return (
    <div className="border-2 p-8 flex flex-1 flex-col bg-background">
      <div className="bg-white rounded-[20px]">
        <div className="flex gap-4 p-4 items-center">
          <div>조회기간</div>

          <CustomDatePicker startDate={startDate} setStartDate={setStartDate} />

          <span>~</span>

          <CustomDatePicker lastDate={lastDate} startDate={startDate} setLastDate={setLastDate} />

          <span>드라이버</span>

          <CustomSelector drivers={drivers} setDriver={setDriver} />

          <button
            onClick={getSales}
            className="rounded-xl bg-[#147ad6] w-[100px] h-[40px] text-white">
            검색
          </button>
        </div>

        <div className="p-4 flex gap-8">
          <div>총 이송금액 : {numberWithCommas(totalFee)}</div>
          <div>총 매칭수수료 : {numberWithCommas(totalCommission)}</div>
          <div>총 매출금액 : {numberWithCommas(totalSale)}</div>
        </div>
      </div>

      <div className="bg-white mt-8 px-8 py-6 rounded-[20px]">
        <table className="w-full border-b">
          <thead>
            <tr className="border-b">
              <th className="p-1">순번</th>
              <th>날짜</th>
              <th>드라이버명</th>
              <th>출발병원</th>
              <th>도착병원</th>
              <th>이송금액</th>
              <th>매칭수수료</th>
              <th>매출금액</th>
            </tr>
          </thead>

          <tbody>
            {orders.length > 0 &&
              orders.map((o, idx) => (
                <tr key={o.id} className="text-center">
                  <td className="py-2">{idx + 1 + listNum * (selectPage - 1)}</td>
                  <td>{dayjs(o.completedAt).format('YYYY-MM-DD')}</td>
                  <td className={`${o.driver.deletedAt ? 'italic line-through' : null}`}>
                    {o.driver.name}
                  </td>
                  <td>{o.departure.name}</td>
                  <td>{o.destination.name}</td>
                  <td>{numberWithCommas(o.invoice.totalFee)}</td>
                  <td>{numberWithCommas(o.invoice.heroFee)}</td>
                  <td>{numberWithCommas(o.invoice.totalFee - o.invoice.heroFee)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {total !== 0 && (
        <Pagination
          listNum={listNum}
          total={total}
          selectPage={selectPage}
          setSelectPage={setSelectPage}
        />
      )}
    </div>
  )
}

export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
