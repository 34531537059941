import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { registerAgencyAsync, selectAgency, updateAgencyAsync } from '../state/agencySlice'
import { useAppDispatch, useAppSelector } from '../state/store'
import DaumPostcode from 'react-daum-postcode'
import usePhoneNumberFormat from '../hooks/usePhonuNumberFormat'
import axios from '../api/axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { default as axiosTrue } from 'axios'
import useLicenseNumberFormat from '../hooks/useLicenseNumberFormat'

export type Coordinate = {
  longitude: string
  latitude: string
}

const RegisterAgencyPage = () => {
  const agency = useAppSelector(selectAgency)
  const registered = agency.id > 0

  const [name, setName] = useState(agency.name)
  const [checkName, setCheckName] = useState(true)
  const [address, setAddress] = useState(agency.address)
  const [checkAddress, setCheckAddress] = useState(true)
  const [email, setEmail] = useState(agency.email)
  const [checkEmail, setCheckEmail] = useState(true)
  const [certificate, setCertificate] = useState(agency.certificate)
  const [affiliation, setAffiliation] = useState(agency.affiliation ? agency.affiliation : '서울')
  const [coordinate, setCoordinate] = useState<Coordinate>(agency.coordinate)
  const [detailAddress, setDetailAddress] = useState(agency.detailAddress)

  const { phoneNumber, handlePhoneNumberChanged } = usePhoneNumberFormat(agency.phoneNumber)
  const [chcekPhone, setCheckPhone] = useState(true)
  const { licenseNumber, handleLicenseNumberChanged } = useLicenseNumberFormat(agency.licenseNumber)
  const [chcekLicense, setCheckLicense] = useState(true)

  const title = !registered ? '업체등록' : '업체수정'
  const buttonLabel = !registered ? '등록신청' : '수정'

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const phonuNumberRef = useRef<HTMLInputElement>(null)
  const licenseNumberRef = useRef<HTMLInputElement>(null)

  useEffect(() => {}, [navigate, registered])

  const register = (e: any) => {
    e.preventDefault()
    const emailRule = '[a-zA-Z0-9.-]\\.[a-zA-Z]{2,6}$'

    if (name.length === 0) {
      setCheckName(false)
      return
    }
    if (licenseNumber.length < 9) {
      setCheckLicense(false)
      return
    }
    if (address.length === 0) {
      setCheckAddress(false)
      return
    }
    if (phoneNumber.length < 11) {
      setCheckPhone(false)
      return
    }

    if (email.match(emailRule) === null) {
      setCheckEmail(false)
      return
    }

    if (
      !(
        checkName &&
        chcekLicense &&
        checkAddress &&
        affiliation &&
        coordinate &&
        chcekPhone &&
        checkEmail
      )
    )
      return

    if (!registered)
      dispatch(
        registerAgencyAsync({
          name,
          licenseNumber,
          address,
          detailAddress,
          phoneNumber,
          email,
          type: 'agency',
          certificate,
          affiliation,
          coordinate,
        }),
      )

    if (registered) {
      dispatch(
        updateAgencyAsync({
          id: agency.id,
          name,
          licenseNumber,
          address,
          detailAddress,
          phoneNumber,
          email,
          type: 'agency',
          certificate,
          affiliation,
        }),
      )
    }

    // navigate('/agency')
  }

  const handleUploadImage = async (e: any) => {
    try {
      const exe = e.target.files[0].name.split('.')
      const filename = Date.now() + exe[exe.length - 1]

      const res = await axios.get<{ url: string }>(`/upload/certificate-image-url/${filename}`)

      const res2 = await fetch(
        new Request(res.data.url, {
          method: 'PUT',
          body: e.target.files[0],
          headers: new Headers({
            'Content-Type': 'image/*',
          }),
        }),
      )

      if (res2.status !== 200) return alert('죄송합니다. 잠시 후 다시 이용해 주세요.')

      setCertificate(
        `https://goochoori.s3.ap-northeast-2.amazonaws.com/certificate-images/${filename}`,
      )
    } catch (error) {
      console.log(error)
    }
  }

  const getCoordinate = async (roadAddress: string) => {
    try {
      const res = await axiosTrue.get(
        `https://dapi.kakao.com/v2/local/search/address?query=${roadAddress}`,
        {
          headers: {
            Authorization: `KakaoAK bf551c43508f70aa9311b4a6a4fcbccc`,
          },
        },
      )

      const result = res.data.documents[0]

      setCoordinate({ longitude: result.x, latitude: result.y })
      setAddress(roadAddress)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <section className="relative px-4 py-10 bg-background flex-1 flex flex-col ">
      <form
        className="mx-auto flex flex-row w-3/4 h-full gap-10 justify-center"
        onSubmit={register}>
        <div className="w-[480px] px-8 bg-white rounded-lg py-10">
          <h1 className="mb-4 text-2xl text-center font-bold">{title}</h1>
          <div className="flex flex-col  mt-3">
            <label htmlFor="name" className="mb-2">
              업체명
            </label>
            <input
              value={name}
              onChange={e => {
                setCheckName(true)
                setName(e.target.value)
              }}
              id="name"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!checkName && <p className="text-sm text-red">업체명을 입력해주세요.</p>}

          <div className="flex flex-col mt-3">
            <label htmlFor="licenseNumber" className="mb-2">
              사업자 등록번호
            </label>
            <input
              ref={licenseNumberRef}
              value={licenseNumber}
              onChange={e => {
                setCheckLicense(true)
                handleLicenseNumberChanged(e)
              }}
              id="licenseNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!chcekLicense && (
            <p className="text-sm text-red">사업자 등록번호가 올바르지 않습니다.</p>
          )}

          <div className="relative flex flex-col mt-3">
            <label htmlFor="address" className="mb-2">
              주소
            </label>
            <input
              autoComplete="off"
              onFocus={e => {
                e.target.blur()
                setCheckAddress(true)
                setIsModalOpen(true)
              }}
              value={address}
              onChange={e => setAddress(e.target.value)}
              id="address"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          {isModalOpen && (
            <div className="relative">
              <div className="absolute p-4 bg-[#ECECEC] rounded w-96">
                <div className="flex justify-between mb-3">
                  <div className="">
                    <h3 className="text-xl font-semibold">주소검색</h3>
                  </div>
                  <div className="">
                    <button
                      className="px-2 py-1 text-xs bg-white rounded-lg"
                      onClick={() => setIsModalOpen(false)}>
                      닫기
                    </button>
                  </div>
                </div>
                <DaumPostcode
                  defaultQuery={address}
                  onComplete={address => getCoordinate(address.roadAddress)}
                  onClose={() => {
                    setIsModalOpen(false)
                    phonuNumberRef.current?.focus()
                  }}
                  autoClose={true}
                />
              </div>
            </div>
          )}
          {!checkAddress && <p className="text-sm text-red">주소가 올바르지 않습니다.</p>}

          <div className="flex flex-col mt-3">
            <label htmlFor="detailAddress" className="mb-2">
              상세주소
            </label>
            <input
              value={detailAddress}
              onChange={e => {
                setDetailAddress(e.target.value)
              }}
              id="phoneNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>

          <div className="flex flex-col mt-3">
            <label htmlFor="phoneNumber" className="mb-2">
              전화번호
            </label>
            <input
              ref={phonuNumberRef}
              value={phoneNumber}
              onChange={e => {
                setCheckPhone(true)
                handlePhoneNumberChanged(e)
              }}
              id="phoneNumber"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!chcekPhone && <p className="text-sm text-red">전화번호가 올바르지 않습니다..</p>}

          <div className="flex flex-col mt-3">
            <label htmlFor="email" className="mb-2">
              이메일
            </label>
            <input
              value={email}
              onChange={e => {
                setCheckEmail(true)
                setEmail(e.target.value)
              }}
              id="email"
              type="text"
              className="p-2 mb-2 border rounded-md"
            />
          </div>
          {!checkEmail && <p className="text-sm text-red">이메일이 올바르지 않습니다.</p>}

          <div className="flex flex-col mt-3">
            <label htmlFor="affiliation" className="mb-2">
              소속 지역
            </label>
            <select
              value={affiliation}
              onChange={e => setAffiliation(e.target.value)}
              id="affiliation"
              className="p-2 mb-2 border rounded-md">
              <option value="강원">강원도</option>
              <option value="경기">경기도</option>
              <option value="경남">경상남도</option>
              <option value="경북">경상북도</option>
              <option value="광주">광주광역시</option>
              <option value="대구">대구광역시</option>
              <option value="부산">부산광역시</option>
              <option value="서울">서울특별시</option>
              <option value="세종">세종특별자치시</option>
              <option value="울산">울산광역시</option>
              <option value="인천">인천광역시</option>
              <option value="전남">전라남도</option>
              <option value="전북">전라북도</option>
              <option value="제주">제주특별자치도</option>
              <option value="충남">충청남도</option>
              <option value="충북">충청북도</option>
            </select>
          </div>

          <div className="flex mt-5 justify-end gap-2">
            <button className="px-4 py-2 text-white bg-blue-400 rounded">{buttonLabel}</button>
          </div>
        </div>

        <div className="w-[480px] bg-white items-center justify-center flex flex-col rounded-lg">
          {/* <label htmlFor="certificate" className="mb-2">
            사업자등록증 추가
          </label> */}
          <input
            onChange={e => handleUploadImage(e)}
            id="certificate"
            type="file"
            accept="image/*"
            className="p-2 mb-2 border hidden"
          />

          <label
            htmlFor="certificate"
            className="w-full h-[650px] items-center justify-center flex flex-col cursor-pointer">
            {certificate ? (
              <img className="block h-full" src={certificate} alt="사업자등록증" />
            ) : (
              <>
                <span>사업자등록증 추가</span>
                <FontAwesomeIcon className="mt-5 text-4xl" icon={faPlus} color="black" />
              </>
            )}
            {!certificate && (
              <p className="texte-sm text-red mt-2">사업자 등록증을 추가해주세요.</p>
            )}
          </label>
        </div>
      </form>
    </section>
  )
}

export default RegisterAgencyPage
