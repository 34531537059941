import { Dispatch, SetStateAction, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function Pagination({
  total,
  listNum,
  setSelectPage,
  selectPage,
}: {
  total: number
  listNum: number
  setSelectPage: Dispatch<SetStateAction<number>>
  selectPage: number
}) {
  const [selectDivPage, setSelectDivPage] = useState(0)
  const limitPage = 10
  const totalPage = Math.ceil(total / listNum)
  const dividPage = Math.ceil(totalPage / limitPage)
  const firstPage = limitPage * (selectDivPage + 1) - (limitPage - 1)
  let lastPage = limitPage * (selectDivPage + 1)

  if (totalPage < lastPage) {
    lastPage = totalPage
  }

  const handleLeftIdx = () => {
    if (selectPage === 1) return
    if (selectPage === firstPage) {
      setSelectDivPage(selectDivPage - 1)
    }
    setSelectPage((pre: number) => pre - 1)
  }

  const handleRightIdx = () => {
    if (selectPage === totalPage) return
    if (selectPage === lastPage && selectPage < totalPage && selectDivPage < dividPage) {
      setSelectDivPage(selectDivPage + 1)
    }

    setSelectPage((pre: number) => pre + 1)
  }

  const handleClick = (idx: number) => {
    setSelectPage(idx + 1 + selectDivPage * limitPage)
  }

  return (
    <div className="flex items-center justify-center gap-3 mt-6 mb-6 select-none">
      <FontAwesomeIcon icon={faArrowLeft} onClick={handleLeftIdx} />
      {Array(lastPage % limitPage === 0 ? limitPage : lastPage % limitPage)
        .fill(0)
        .map((_, idx) => {
          return (
            <div
              className={`${
                idx + 1 + selectDivPage * limitPage === selectPage
                  ? 'text-white bg-[#1471f7]'
                  : 'text-black bg-[#efefef]'
              } w-8 h-8 text-center leading-8 font-bold rounded cursor-pointer`}
              key={idx + 1}
              onClick={() => handleClick(idx)}>
              {idx + 1 + selectDivPage * limitPage}
            </div>
          )
        })}
      <FontAwesomeIcon icon={faArrowRight} onClick={handleRightIdx} />
    </div>
  )
}
