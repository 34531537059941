import { Navigate, Outlet } from 'react-router-dom'
import { selectAgency } from '../state/agencySlice'
import { useAppSelector } from '../state/store'

const RequireAgency = ({ shouldVerified }: { shouldVerified?: boolean }) => {
  const agency = useAppSelector(selectAgency)
  const isAgency = agency.id > 0

  if (!isAgency) return <Navigate to="/agency/register" />
  if (shouldVerified && !agency.isVerified) return <Navigate to="/" />

  return <Outlet />
}

export default RequireAgency
