import { selectAgency } from '../state/agencySlice'
import { useAppSelector } from '../state/store'
import { selectUser } from '../state/userSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle, faRotateRight } from '@fortawesome/free-solid-svg-icons'
import KakaoMap from '../components/KakaoMap'
import RegisterAgencyPage from './AgencyRegisterPage'
import { Navigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import axios from '../api/axios'
import { Driver } from './SalesPage'
import agencyMarker from '../asset/agency_marker.png'
import { Order, TOffer } from '../type/order'
import { printDatetime } from '../lib/util'

const MainPage = () => {
  const agency = useAppSelector(selectAgency)
  const isLoggedIn = useAppSelector(selectUser).id > 0
  const [spin, setSpin] = useState(false)
  const [drivers, setDrivers] = useState<Driver[]>([])
  const [currentRequestedOrder, setCurrentRequestedOrder] = useState<Order | null>(null)
  const [requestedOrderList, setRequestedOrderList] = useState<Order[]>([])
  const [lastOffer, setLastOffer] = useState<TOffer | null>(null)

  const fetchOrgOrder = useCallback(() => {
    axios.get('/order/org-list').then(res => {
      console.log('res:: (/order/org-list)', res.data)
      if (res.data.requestedOrders) setRequestedOrderList(res.data.requestedOrders)
    })
  }, [])

  const fetchOrgOffer = useCallback(() => {
    if (!currentRequestedOrder) {
      setLastOffer(null)
      return
    }

    axios.get(`/order/org/offer?orderId=${currentRequestedOrder.id}`).then(res => {
      console.log('res:: (/order/org/offer?orderId=)', res.data)
      if (res.data.offer) setLastOffer(res.data.offer)
      else setLastOffer(null)
    })
  }, [currentRequestedOrder])

  const fetchDrivers = useCallback(() => {
    axios.get('/organization/drivers').then(res => {
      console.log('drivers: ', res.data.drivers.length)
      setDrivers(res.data.drivers)
      // setDrivers(res.data.drivers.filter(d => d.status !== 'rest'))
    })
  }, [])

  const fetchAll = () => {
    fetchOrgOrder()
    fetchOrgOffer()
    fetchDrivers()
  }

  useEffect(() => {
    if (!isLoggedIn) return

    fetchDrivers()
  }, [spin, isLoggedIn])

  useEffect(() => {
    if (!isLoggedIn) return
    console.log('agency.isVerified: ', agency.isVerified)
    if (!agency.isVerified) return

    fetchAll()
    const timerId = setInterval(fetchAll, 5000)
    return () => {
      clearInterval(timerId)
    }
  }, [isLoggedIn, agency])

  useEffect(() => {
    if (!requestedOrderList.length) return

    setCurrentRequestedOrder(requestedOrderList[0])
  }, [requestedOrderList])

  if (!isLoggedIn) return <Navigate to="/login" />

  return !agency.name ? (
    <div className="flex-1 flex">
      <RegisterAgencyPage />
    </div>
  ) : (
    <div className="p-4 flex flex-1 flex-row gap-6 bg-background">
      {agency.isVerified ? (
        <>
          {/* 호출 및 예약 현황 */}
          <div className="w-3/12 bg-white rounded-lg">
            <div className="border-b-gray-200 border-b p-5 font-semibold text-center">
              <p className="">호출 및 예약 현황</p>
              <p className="font-light">총 {requestedOrderList.length}건</p>
            </div>

            <ul className="px-5 pb-5">
              {requestedOrderList.map(order => {
                return (
                  <li key={order.id} className="flex flex-fow justify-between items-center pt-5 ">
                    <div style={{ flex: 1 }}>
                      <p className="text-[#323232] text-lg font-bold">
                        {printDatetime(new Date(order.createdAt))}
                      </p>
                      <p className="text-[#323232] text-lg font-bold">
                        도착지: {order.departure.name}
                      </p>
                    </div>
                    <button
                      disabled
                      className={`rounded-xl bg-[#aeaeae] w-[80px] h-[34px] text-white`}>
                      {order.reservationId ? '예약' : '호출'}
                    </button>
                  </li>
                )
              })}
            </ul>
          </div>
          {/* 지도 */}
          <div className="w-9/12 bg-white rounded-lg ">
            <div className="border-b-gray-200 border-b p-5 flex flex-row justify-between">
              <div className="flex gap-2">
                <p className="">{agency.name} 인근 구급차 포진 현황</p>
                <p
                  className={`${spin && 'animate-spin'} hover:cursor-pointer`}
                  onClick={() => {
                    setSpin(true)
                    setTimeout(() => setSpin(false), 1000)
                  }}>
                  <FontAwesomeIcon icon={faRotateRight} />
                </p>
              </div>

              <ul className="flex flex-row gap-4">
                <li>
                  <FontAwesomeIcon
                    className="mr-2"
                    width={10}
                    height={10}
                    icon={faCircle}
                    color="#26CB83"
                  />
                  <p className="inline-block">대기 중</p>
                </li>

                <li>
                  <FontAwesomeIcon
                    className="mr-2"
                    width={10}
                    height={10}
                    icon={faCircle}
                    color="#FF8571"
                  />
                  <p className="inline-block">이송 중</p>
                </li>

                <li className="flex gap-1">
                  <img src={agencyMarker} alt="" width={20} />
                  <p className="inline-block">사무소</p>
                </li>
              </ul>
            </div>
            <div className="h-[600px] bg-white p-6">
              <div className="w-full h-full bg-gray-100 items-center justify-center flex">
                <KakaoMap drivers={drivers} />
              </div>
            </div>
          </div>
          {/* 실시간 현황 */}
          <div className="w-3/12 bg-white rounded-lg">
            <div className="border-b-gray-200 border-b p-5 font-semibold text-center">
              <p className="">실시간 현황</p>
              {!currentRequestedOrder && <p className="font-light">조치할 이송요청이 없습니다.</p>}
            </div>
            <ul className="px-5 pb-5">
              {drivers.map(d => {
                const canOffer = !!currentRequestedOrder && d.status === 'ready'
                const isOffering = lastOffer?.user?.id === d.id

                return (
                  <li key={d.id} className="flex flex-fow justify-between items-center pt-5 ">
                    <div className="flex flex-row items-center">
                      <div className="w-10 h-10 bg-gray-100 overflow-hidden rounded-full">
                        <img className="object-cover" src={d.profileImage} alt="" />
                      </div>
                      <p className="ml-3 text-[#323232] text-lg font-bold">{d.name}</p>
                    </div>
                    <div className="flex flex-col items-center">
                      <button
                        onClick={() => {
                          if (!currentRequestedOrder) return

                          // 출동취소
                          if (isOffering) {
                            axios
                              .post('/order/offer/cancel', { offerId: lastOffer.id })
                              .finally(fetchAll)
                            return
                          }

                          // 출동
                          if (d.isAgencyMaster) {
                            alert('자신을 출동시키려면 앱에서 진행해주세요.')
                            return
                          }

                          axios
                            .post(`/order/offerManual`, {
                              orderId: currentRequestedOrder.id,
                              driverId: d.id,
                            })
                            .finally(fetchAll)
                        }}
                        disabled={!canOffer}
                        className={`rounded-xl ${
                          canOffer ? 'bg-[#147ad6]' : 'bg-[#aeaeae]'
                        } w-[100px] h-[40px] text-white`}>
                        {isOffering ? '취소' : '출동'}
                      </button>
                      <p
                        className={`font-bold ${
                          d.status === 'working' ? 'text-[#FF8571]' : 'text-[#26CB83]'
                        }`}>
                        {d.status === 'working'
                          ? '이송 중'
                          : d.status === 'ready'
                          ? '대기 중'
                          : '출근 전'}
                      </p>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>{' '}
        </>
      ) : (
        <div className="flex-1 ">
          <div className="bg-white py-5">
            <p className="text-center text-red-500 text-2xl">승인 대기중 입니다.</p>
            <p className="text-center mt-2">승인 대기중에도 업체 정보를 수정 할 수 있습니다. </p>
          </div>
          <RegisterAgencyPage />
        </div>
      )}
    </div>
  )
}

export default MainPage
