import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "../api/axios";

interface Invoice {
  id: number;
  type: string;
  totalFee: number;
  driverFee: number;
  heroFee: number;
  goochooriFee: number;
  transferStartedAt: string;
  transferFinishedAt: string;
  calculatedAt: string;
}

export default function SalesDetailPage() {
  const { id } = useParams();
  const [sales, setSales] = useState<Invoice[]>([]);

  useEffect(() => {
    const fetchDriverSales = async () => {
      const res = await axios.get<{ invoice: Invoice[] }>(
        `/organization/sales/${id}`
      );
      setSales(res.data.invoice);
    };

    fetchDriverSales();
  }, [id]);
  return (
    <>
      <div>SalesDetailPage</div>
      <pre>{JSON.stringify(sales, null, 2)}</pre>
    </>
  );
}
