import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../api/axios'
import { Coordinate } from '../pages/AgencyRegisterPage'
import { RootState } from './store'

export interface Agency {
  [index: string]: any
  id: number
  name: string
  type: 'agency'
  licenseNumber: string
  address: string
  detailAddress: string
  phoneNumber: string
  profileImage: string
  email: string
  isVerified: boolean
  certificate: string
  affiliation: string
  coordinate: Coordinate
}

const initialState: Agency = {
  id: -1,
  name: '',
  licenseNumber: '',
  address: '',
  detailAddress: '',
  profileImage: '',
  email: '',
  isVerified: false,
  phoneNumber: '',
  type: 'agency',
  certificate: '',
  affiliation: '',
  coordinate: {
    longitude: '',
    latitude: '',
  },
}

export const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {
    setAgency: (state, action: PayloadAction<Partial<Agency>>) => {
      if (action.payload?.type !== 'agency') return
      Object.keys(state).forEach(key => {
        if (action.payload[key]) state[key] = action.payload[key]
      })
    },
    resetAgency: state => {
      Object.assign(state, initialState)
    },
  },
  extraReducers: builder =>
    builder
      .addCase(registerAgencyAsync.fulfilled, (state, action) => {
        if (action.payload?.type !== 'agency') return
        Object.keys(state).forEach(key => {
          if (action.payload?.[key]) state[key] = action.payload[key]
        })
      })
      .addCase(registerAgencyAsync.rejected, (state, action) => {
        console.log(action.payload)
      })
      .addCase(updateAgencyAsync.fulfilled, (state, action) => {
        if (action.payload?.type !== 'agency') return
        Object.keys(state).forEach(key => {
          if (action.payload?.[key]) state[key] = action.payload[key]
        })
      }),
})

export const updateAgencyAsync = createAsyncThunk(
  'agency/updateAgencyAsync',
  async (agency: Partial<Agency>) => {
    const res = await axios.put<{
      success: boolean
      organization: Partial<Agency>
    }>('/organization/update', agency)

    if (res.data.success) {
      alert('수정되었습니다.')
      return res.data.organization
    }
  },
)

export const registerAgencyAsync = createAsyncThunk(
  'agency/registerAgencyAsync',
  async (agency: Partial<Agency>) => {
    const res = await axios.post<{
      success: boolean
      organization: Partial<Agency>
    }>('/organization/register', agency)

    if (res.data.success) {
      alert('신청이 완료 되었습니다. 담당자가 곧 확인 후 연락 드리겠습니다. 감사합니다.')
      return res.data.organization
    }
  },
)

export const { resetAgency, setAgency } = agencySlice.actions

export const selectAgency = (state: RootState) => state.agency

export default agencySlice
