import { Dispatch, SetStateAction } from 'react'
import { Driver } from '../pages/SalesPage'

type CustomSelectProps = {
  drivers: Driver[]
  setDriver: Dispatch<SetStateAction<string>>
}

export default function CustomSelector({ drivers, setDriver }: CustomSelectProps) {
  return (
    <div className="flex custom_select" onClickCapture={() => console.log}>
      <select
        onChange={e => setDriver(e.target.value)}
        className={`border border-[#cdcdcd] pl-2 pr-4 w-32 h-8  appearance-none`}>
        <option value="">드라이버 선택</option>
        {drivers.length > 0 &&
          drivers.map(d => (
            <option key={d.id} value={d.id}>
              {d.name}
            </option>
          ))}
      </select>
    </div>
  )
}
