import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from '../api/axios'
import { resetAgency } from './agencySlice'
import { RootState } from './store'

export interface User {
  [index: string]: any
  id: number
  name: string
  profileImage: string
  email: string
  role: 'agency'
}

const initialState: User = {
  id: -1,
  name: '',
  profileImage: '',
  email: '',
  role: 'agency'
}

export const logoutAsync = createAsyncThunk(
  'user/logoutAsync',
  async (_, api) => {
    const response = await axios.get('/auth/logout', { withCredentials: true })

    axios.defaults.headers.common.authorization = false

    api.dispatch(resetAgency())
    return response.status
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Partial<User>>) => {
      if (action.payload.role !== 'agency') return
      Object.keys(state).forEach(key => {
        if (action.payload[key]) state[key] = action.payload[key]
      })
    },
    resetUser: state => {
      Object.assign(state, initialState)
    }
  },
  extraReducers: builder =>
    builder.addCase(logoutAsync.fulfilled, state => {
      Object.assign(state, initialState)
    })
})

export const { resetUser, setUser } = userSlice.actions

export const selectUser = (state: RootState) => state.user
export const selectIsLoggedIn = (state: RootState) => state.user.id > -1

export default userSlice
