import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from '../api/axios'
import { faPen, faCirclePlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Driver } from '../type/driver'

const DriversPage = () => {
  const [invitations, setInvitations] = useState<Driver[]>([])

  useEffect(() => {
    const fetchInvitations = async () => {
      const res = await axios.get('/invitations')
      console.log(JSON.stringify(res.data, null, 2))
      setInvitations(res.data)
    }

    fetchInvitations()
  }, [])

  return (
    <div className="p-4 flex-1 bg-background">
      <h2 className="text-3xl mb-5 ">직원관리</h2>
      <ul className="flex-1 flex flex-wrap gap-4 flex-fow">
        {invitations.map((invitation: Driver) => (
          <li key={invitation.id} className="relative p-4 w-[355px] bg-white rounded-lg">
            <Link
              className="absolute top-2 right-4 p-2"
              to={`/drivers/invitations/update/${invitation.id}`}
              onClick={e => e.stopPropagation()}>
              <FontAwesomeIcon className="text-sideBar" icon={faPen} color="white" />
            </Link>
            <div className="flex flex-row items-center">
              <h3 className="text-xl font-bold">{invitation.name}</h3>
              <p className="ml-3 text-lg text-gray-400">초대코드 : {invitation.code}</p>
            </div>
            <p className="mt-1 text-gray-400">{invitation.address}</p>
            <p className="mt-1 text-gray-400">{invitation.email}</p>
            <div className="mt-1 flex flex-row justify-between">
              <p className="text-gray-400">{invitation.phoneNumber}</p>
              <p className="text-gray-400">{invitation.licenseNumber}</p>
            </div>
          </li>
        ))}

        <li className="flex items-center justify-center p-4 w-[355px] bg-white rounded-lg">
          <Link
            className="p-3"
            to="/drivers/invitations/register"
            onClick={e => e.stopPropagation()}>
            <FontAwesomeIcon className="text-5xl" icon={faCirclePlus} color="#ececec" />
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default DriversPage
