import React, { ChangeEvent, useState } from 'react'
import axios from '../api/axios'
import { Vehicle } from '../type/vehicle'

// type Event = React.ChangeEvent<HTMLInputElement>;

export default function VehicleRegister({
  toggleRegisterMode,
  setVehicles,
}: {
  toggleRegisterMode: () => void
  setVehicles: any
}) {
  // const [file, setFile] = useState<File>();

  const [vehicleType, setVehicleType] = useState<'normal' | 'special'>('normal')
  const [vehicleNumber, setVehicleNumber] = useState('')

  // const uploadImage = (e: Event) => {
  //   if (!e.target.files) return;
  //   setFile(e.target.files[0]);
  // };

  const handleVehicleNum = (e: ChangeEvent<HTMLInputElement>) => {
    setVehicleNumber(e.currentTarget.value)
  }

  const registerVichelInfo = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      // if (!file || !vichelNumber) return;

      // const splitFile = file.name.split(".");
      // const newFileName = Date.now() + "." + splitFile[splitFile.length - 1];

      // const res = await axios.get<{ url: string }>(
      //   `/upload/certificate-image-url/${newFileName}`
      // );

      // const putS3 = await fetch(
      //   new Request(res.data.url, {
      //     method: "PUT",
      //     body: file,
      //     headers: new Headers({
      //       "Content-Type": "image/*",
      //     }),
      //   })
      // );

      // if (putS3.status !== 200) alert("다시 시도해 주세요");
      if (vehicleNumber.length < 7) return alert('차량번호를 확인해주세요.')

      const res2 = await axios.post<{ vehicles: Vehicle; success: boolean }>(
        '/organization/agency/vehicle/register',
        {
          // url: `https://goochoori.s3.ap-northeast-2.amazonaws.com/certificate-images/${newFileName}`,
          vehicleNumber,
          type: vehicleType,
        },
      )

      if (!res2.data.success) throw new Error('등록 오류')
      setVehicles(res2.data.vehicles)
      toggleRegisterMode()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <li className="w-64 flex flex-row h-[120px] items-center bg-white rounded-lg overflow-hidden">
      <div className="w-4/5 px-4">
        <ul className="flex flex-row border rounded-md overflow-hidden">
          <li
            className={`flex-1 w-full flex justify-center border-r ${
              vehicleType === 'normal' && 'bg-green'
            }`}>
            <button
              onClick={() => setVehicleType('normal')}
              className={`py-1 text-sm  w-full ${
                vehicleType === 'normal' ? 'text-white' : 'text-gray-500'
              }`}>
              일반
            </button>
          </li>
          <li
            className={`flex-1 w-full flex justify-center ${
              vehicleType === 'special' && 'bg-red'
            }`}>
            <button
              onClick={() => setVehicleType('special')}
              className={`py-1 text-sm  w-full ${
                vehicleType === 'special' ? 'text-white' : 'text-gray-500'
              }`}>
              특수
            </button>
          </li>
        </ul>
        <input
          value={vehicleNumber}
          onChange={handleVehicleNum}
          placeholder="12가 1234"
          className="bg-gray-200 h-10 w-full rounded-md mt-3 text-center outline-none"
        />
      </div>
      <div className="flex-1 flex h-full flex-col border-2 border-sideBar rounded-tr-lg rounded-br-lg ">
        <div className="flex w-full flex-1 items-center justify-center">
          <button onClick={toggleRegisterMode} className="w-full py-2 text-sideBar text-sm">
            취소
          </button>
        </div>
        <div className="flex w-full bg-sideBar flex-1 items-center justify-center">
          <button onClick={registerVichelInfo} className="w-full py-2 text-white text-sm">
            저장
          </button>
        </div>
      </div>
    </li>
  )
}
