import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useEffect } from 'react'
dayjs.extend(relativeTime)
dayjs.locale('ko')

const useDayjs = () => {
  useEffect(() => {
    dayjs.extend(relativeTime)
    dayjs.locale('ko')
  }, [])
}

export default useDayjs
