import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

type Props = {
  item: { id: number; name: string; icon: IconDefinition; path: string }
  isActive: boolean
}

function SideBarItem({ item, isActive }: Props) {
  return (
    <li
      className={
        isActive
          ? 'relative flex-row items-center bg-sideBar-300 after:absolute after:w-2 after:h-full after:top-0 after:left-0 after:bg-sideBar-100'
          : ''
      }>
      <Link className="text-lg text-white w-full block py-4 pl-10" to={item.path}>
        <FontAwesomeIcon className="mr-4" icon={item.icon} color="white" />
        {item.name}
      </Link>
    </li>
  )
}

export default SideBarItem
